import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import { Article } from '@/interfaces/models/Article';
import { StockManagementType } from '@/enums/StockManagementType';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { Localization } from '@/interfaces/models/Localization';

const api: FoodcardApiService = new FoodcardApiService();

interface GroupedArticle {
  parentName: string;
  connectedArticles: { de: string }[];
  parentDescription: string;
  article: Article;
  type: StockManagementType;
}

interface ArticleManagerState {
  groupedArticles: { article: GroupedArticle[]; option: GroupedArticle[] };
  filter: Filter;
}

const state: ArticleManagerState = {
  groupedArticles: { article: [], option: [] },
  filter: {},
};

const actions: ActionTree<ArticleManagerState, RootState> = {
  setGroupedArticle({ commit }, data: { article: GroupedArticle[]; option: GroupedArticle[] }) {
    commit('setGroupedArticle', data);
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  makeArticleVisible({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeArticleVisible(data).then(() => {
      commit('toggleArticleVisibility', { value: true, articles: data.articles });
    });
  },
  hideArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.hideArticle(data).then(() => {
      commit('toggleArticleVisibility', { value: false, articles: data.articles });
    });
  },
  makeArticleOptionVisible({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeArticleOptionVisible(data).then(() => {
      commit('toggleOptionArticleVisibility', { value: true, articles: data.articles });
    });
  },
  hideOptionArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.hideOptionArticle(data).then(() => {
      commit('toggleOptionArticleVisibility', { value: false, articles: data.articles });
    });
  },
  reset({ commit }) {
    commit('reset');
  },
};

const mutations: MutationTree<ArticleManagerState> = {
  setGroupedArticle(state: ArticleManagerState, data: { article: GroupedArticle[]; option: GroupedArticle[] }) {
    state.groupedArticles = data;
  },
  setFilter(state: ArticleManagerState, data: Filter) {
    state.filter = data;
  },
  toggleArticleVisibility(state: ArticleManagerState, data: { value: boolean; articles: string[] }) {
    const articles: GroupedArticle[] = state.groupedArticles.article.filter(
      (a: GroupedArticle) => data.articles.indexOf(a.article._id) > -1,
    );
    for (const article of articles) {
      article.article.visible = data.value;
    }
  },
  toggleOptionArticleVisibility(state: ArticleManagerState, data: { value: boolean; articles: string[] }) {
    const articles: GroupedArticle[] = state.groupedArticles.option.filter(
      (a: GroupedArticle) => data.articles.indexOf(a.article._id) > -1,
    );
    for (const article of articles) {
      article.article.visible = data.value;
    }
  },
  reset(state: ArticleManagerState) {
    state.filter = {};
    state.groupedArticles = { article: [], option: [] };
  },
};

const getters: GetterTree<ArticleManagerState, RootState> = {
  articles: (state) => state.groupedArticles.article,
  optionArticles: (state) => state.groupedArticles.option,
  filteredArticles: (state) => {
    return state.groupedArticles?.article?.filter((article) => {
      const searchValue = state.filter?.search?.toLowerCase();
      const name = article.article?.name;

      const matchesName =
        !searchValue ||
        (name &&
          (Object.keys(name) as (keyof Localization)[]).some((key) => name[key]?.toLowerCase().includes(searchValue)));

      return (
        (!state.filter?.category || article.article?.categoryId === state.filter?.category) &&
        matchesName &&
        (state.filter?.status === 'all' ||
          state.filter?.status === undefined ||
          (state.filter?.status === 'active' ? article.article?.visible : !article.article?.visible))
      );
    });
  },
  filteredOptionArticles: (state) => {
    return state.groupedArticles?.option?.filter((article) => {
      const searchValue = state.filter?.search?.toLowerCase();
      const name = article.article?.name;

      const matchesName =
        !searchValue ||
        (name &&
          (Object.keys(name) as (keyof Localization)[]).some((key) => name[key]?.toLowerCase().includes(searchValue)));

      return (
        (!state.filter?.category || article.article?.categoryId === state.filter?.category) &&
        matchesName &&
        (state.filter?.status === 'all' ||
          state.filter?.status === undefined ||
          (state.filter?.status === 'active' ? article.article?.visible : !article.article?.visible))
      );
    });
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
